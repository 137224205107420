<template>
  <v-container fluid class="ma-0 pa-0">
    <title>Category Page</title>

    <Loading
        title="Loading category details"
        :loading="categorySelectedLoading"
        class="pp-full-width"
    />

    <div v-if="! categorySelectedLoading">
      <CategoryHeader :category="categorySelected" />
    </div>

    <v-container class="px-xl-16" fluid>

      <Loading
          title="Loading category courses"
          :loading="categoryCoursesLoading"
          class="mt-16 pp-full-width pp-vh"
      />

      <div v-if="! categoryCoursesLoading"
           class="px-xl-16 mt-xl-8"
      >

        <div class="text-h5 font-weight-bold pt-10 px-xl-16 ml-8">
          Courses to get you started
          <div class="pp-hr mb-5"></div>
        </div>

        <v-row class="px-xl-16">
          <v-col cols="12" sm="5" md="4" lg="3" xl="3"
                 v-for="course in categoryCourses"
                 :key="course._id"
                 class="px-xl-4"
          >
            <CourseCompact :course="course" />
          </v-col>
        </v-row>
        <v-divider class="my-4" />
      </div>
    </v-container>

    <Pagination v-if="categoryCoursesPagination"
                :options="categoryCoursesPagination"
                :callback="loadCategoryCourses"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Loading from "@/components/common/Loading";
import Pagination from "@/components/common/Pagination";
import CategoryHeader from "@/components/category/CategoryHeader";
import CourseCompact from "@/components/course/CourseCompact";

export default {
  name: "ViewCategory",
  components: {CourseCompact, CategoryHeader, Pagination, Loading},
  metaInfo() {
    return {
      title: this.categorySelected ? `Category: ${this.categorySelected.name}` : 'Category',
      meta:[
        { description: this.categorySelected ? this.categorySelected.description : 'Category description' },
        { image: this.categorySelected ? this.categorySelected.image : 'Category image' },
      ],
    };
  },
  computed: {
    ...mapState([
      'categorySelected',
      'categorySelectedLoading',
      'categoryCourses',
      'categoryCoursesLoading',
      'categoryCoursesPagination',
    ]),
    ...mapGetters([
      'categorySelected',
      'categorySelectedLoading',
      'categoryCourses',
      'categoryCoursesLoading',
      'categoryCoursesPagination',
    ]),
  },
  methods: {
    ...mapActions(['getCategoryBySlug', 'getCategoryCoursesByCategorySlug', 'setCourseSelected']),
    loadCategoryCourses: function(page){
      this.getCategoryCoursesByCategorySlug(
          {
            category: this.$route.params.slug,
            inputOptions: {page: page},
          },
      );
    },
  },
  async created() {

    await this.getCategoryBySlug(this.$route.params.slug);

    if(!this.categorySelected){
      await this.$router.replace("/not-found");
    }
    this.loadCategoryCourses(1);
  },
};
</script>

<style>
.pp-vh {
  height: 50vh;
}
</style>
