<template>
  <div>
    <v-container
        class="blue-grey darken-4 pt-13"
        fluid
    >
      <v-container class="px-xl-16">
        <v-row class="mt-md-16">
          <v-col>
            <GoBackButton class="mb-md-16 mb-7 "/>

            <div class="white--text">
              Category
              <v-icon color="white">
                mdi-chevron-right
              </v-icon>
            </div>

            <h1
                class="text-md-h3 text-h5 pp-page-title pp-left-line"
            >
              {{category.name}}
            </h1>

            <h2 class="white--text my-5 text-h71 font-weight-medium pre-line">
              {{category.description}}
            </h2>
          </v-col>

          <v-col cols="5" class="hidden-xs-only">
            <v-row class="pp-absolute pl-xl-16 ml-xl-12">
              <picture v-if="category.image">
                <img :src="category.image.url"
                    :alt="category.image.alt"
                    class="rounded-xl elevation-10 hidden-sm-and-down cover"
                    height="380"
                    width="500"
                />
              </picture>

              <picture v-else>
                <img

                    class="rounded-xl elevation-10 hidden-xs-only cover"
                    src="/images/categories/pp-default.jpg"
                    alt="LearningPlus Category"
                    height="400"
                    :width="width"
                />
              </picture>

            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>

import GoBackButton from "@/components/buttons/GoBackButton";
export default {
  name: "CategoryHeader",
  components: {GoBackButton},
  props: {
    category: Object,
  },

  computed: {
    width () {
      if (this.$vuetify.breakpoint.name === 'sm') {
        return 330;
      }
      return 450;
    },
  },
};
</script>
