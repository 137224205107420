<template>
  <div>
    <v-btn @click="goBack"
           rounded
           plain
           class="pl-0 text-subtitle-1 white--text"
           title="Go back"
           aria-labelledby="Go back to previous page"
    >
      <v-icon>
        mdi-chevron-left
      </v-icon>
      Back
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "GoBackButton",
  methods: {
    goBack() {
      return this.$router.go(-1);
    },
  },
};
</script>